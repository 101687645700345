<template>
  <div class="ConfiguringReports">
    <div class="head_img">
      <div class="img_title">
        <div class="img">
          <img class="img" v-if="topinfo.projectImg" :src="demandinfo.projectImg" alt="" />
          <img class="img" v-else src="../../../assets/image/Group 3163.png" alt="" />
        </div>
        <div class="title">
          <p class="merchant_name">{{ topinfo.projectName }}</p>
          <div class="interval">
            <span style="position: relative; left: -1px; color: #078bff">
              <img src="../../../assets/image/jr-icon-zhong.png" width="14" height="14" alt="" />
            </span>
            <span>时间：{{ topinfo.startTime }}至{{ topinfo.endTime }}</span>
          </div>
          <div class="interval" style="padding: 7px 0px">
            <span style="position: relative; left: -1px; top: 2px">
              <img src="../../../assets/image/jr-icon-xingxing.png" width="14" height="14" alt="" />
            </span>
            <span style="margin-left: 3px">{{ demandinfo.realName }}</span>
            <span v-if="demandinfo.chamberCommerceName"
              >({{ demandinfo.chamberCommerceName }})</span
            >
            <span v-if="demandinfo.chamberCommerceJob"> ({{ demandinfo.chamberCommerceJob }})</span>
          </div>
          <div class="interval">
            <span style="position: relative; left: -1px; top: 2px">
              <img src="../../../assets/image/jr-icon-dianhua.png" width="14" height="14" alt="" />
            </span>
            <span>{{ demandinfo.phone }}</span>
          </div>
        </div>
      </div>
       <div>
          <el-button type="warning" @click="releaseClick">发布报告</el-button>
        </div>
    </div>
    <div class="essential_information">
      <div style="display:flex;">
        <h4 style="color: #146aff; padding-left: 30px">基本信息</h4>
      <div class="top_info" style="margin-left:30px" >
          <span>
            <img src="../../../assets/image/Volume Up.png" alt="">
          </span>
          <div class="top_time">
            <div>
              <span style="color: #75757D;">最新更新时间：</span>
            <span>{{ topinfo.updateTime }}</span>
            </div>

            <div>
              <span style="color:#75757D">更新人：</span>
            <span>{{ topinfo.updateName }}</span>
            </div>
          </div>
        </div>
       
      </div>
      <div class="form_essential">
        <el-form ref="form" :model="form" label-width="110px">
          <el-collapse v-model="activeNames">
            <el-collapse-item title="项目信息" name="1">
              <div style="padding: 30px 0px 10px 0px">
                <el-form-item label="封面图:" prop="coverImages">
                  <div class="com_upload">
                    <img-big-upload
                      :imageShow.sync="form.coverImages"
                      :addTitleShow="false"
                      @removeImg="removeImgDian"
                      @uploadOneImgShow="uploadOneImgShowDian"
                    />
                  </div>
                  <div class="img_tips">
                    <span style="font-size: 12px; color: #146aff"
                      >(建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于12MB)</span
                    >
                  </div>
                </el-form-item>
                <el-form-item label="项目名称:" prop="projectName">
                  <el-input v-model="form.projectName" placeholder="请输入项目名称" />
                </el-form-item>

                <el-form-item label="考察领域:" prop="areasInvestigation">
                  <div class="general_input">
                    <div class="input_flex" v-for="(item, index) in list" :key="index">
                      <div class="name_form">
                        <el-cascader
                          v-model="item.areas"
                          :options="operaList"
                          :props="{
                            label: 'codeName',
                            value: 'codeName',
                            children: 'codeMasterFieldResultList',
                            expandTrigger: 'hover',
                            multiple: false
                          }"
                        ></el-cascader>
                      </div>
                      <div style="display: flex">
                        <span v-if="index == 0" style="color: #4e93fb" @click="addareas"
                          ><i class="el-icon-circle-plus"></i
                        ></span>
                        <span v-if="index > 0" style="color: #ff7575" @click="delareas(index)"
                          ><i class="el-icon-delete"></i
                        ></span>
                      </div>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="一句话介绍:" prop="projectIntroduction">
                  <div class="txtree">
                    <el-input
                      v-model="form.projectIntroduction"
                      type="textarea"
                      :autosize="{ minRows: 3, maxRows: 4 }"
                      placeholder="请输入内容"
                      maxlength="50"
                      show-word-limit
                    />
                  </div>
                </el-form-item>
              </div>
            </el-collapse-item>
            <el-collapse-item title="参访信息" name="2">
              <div style="padding: 30px 0px 10px 0px">
                <el-form-item label="考察人员:" prop="inspectNumber">
                  <div class="proportion">
                    <el-input-number
                      v-model="form.inspectNumber"
                      :min="1"
                      :max="10000"
                      label="描述文字"
                    ></el-input-number>
                  </div>

                  <div class="txtree">
                    <el-input
                      v-model="form.inspectIntroductory"
                      type="textarea"
                      :autosize="{ minRows: 3, maxRows: 4 }"
                      placeholder="请输入内容"
                      maxlength="50"
                      show-word-limit
                    />
                  </div>
                </el-form-item>
                <el-form-item label="参访嘉宾:" prop="participants">
                  <div class="proportion">
                    <el-input-number
                      v-model="form.participants"
                      :min="1"
                      :max="10000"
                      label="描述文字"
                    ></el-input-number>
                  </div>
                  <div class="txtree">
                    <el-input
                      v-model="form.participantsIntroductory"
                      type="textarea"
                      :autosize="{ minRows: 3, maxRows: 4 }"
                      placeholder="请输入内容"
                      maxlength="50"
                      show-word-limit
                    />
                  </div>
                </el-form-item>
                <el-form-item label="参访企业:" prop="companyNumber">
                  <div class="proportion">
                    <el-input-number
                      v-model="form.companyNumber"
                      :min="1"
                      :max="10000"
                      label="描述文字"
                    ></el-input-number>
                  </div>
                  <div class="txtree">
                    <el-input
                      v-model="form.companyIntroductory"
                      type="textarea"
                      :autosize="{ minRows: 3, maxRows: 4 }"
                      placeholder="请输入内容"
                      maxlength="50"
                      show-word-limit
                    />
                  </div>
                </el-form-item>
              </div>
            </el-collapse-item>
            <el-collapse-item title="成果展示" name="3">
              <el-form-item label="成果展示:" prop="commodityName">
                <div class="pictures" style="margin-top: 30px">
                  <div
                    style="display: flex"
                    v-for="(item, index) in form.resultOutcomes"
                    :key="index"
                  >
                    <div style="margin-bottom: 10px">
                      <el-form-item label="是否有意向合作:" prop="" label-width="150px">
                        <el-radio-group v-model="item.isIntention">
                          <el-radio :label="'1'">是</el-radio>
                          <el-radio :label="'0'">否</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item label="合作意向企业:" prop="" label-width="120px">
                        <div class="cooperation">
                          <el-input v-model="item.companyName" placeholder="请输入合作意向企业" />
                          <div class="proportion">
                            <el-input
                              v-model="item.percentage"
                              placeholder="请填写合作意向百分比"
                            />
                            <span style="font-size: 12px; padding: 0px 5px">%</span>
                          </div>
                        </div>
                      </el-form-item>
                      <el-form-item label="合作意向描述:" prop="" label-width="120px">
                        <div class="txtree">
                          <el-input
                            v-model="item.intentionDescription"
                            type="textarea"
                            :autosize="{ minRows: 3, maxRows: 4 }"
                            placeholder="请输入内容"
                            maxlength="50"
                      show-word-limit
                          />
                        </div>
                      </el-form-item>
                    </div>
                    <div style="padding: 0px 20px">
                      <span v-if="index == 0" style="color: #4e93fb" @click="addiption"
                        ><i class="el-icon-circle-plus"></i
                      ></span>
                      <span v-if="index > 0" style="color: #ff7575" @click="deliption(index)"
                        ><i class="el-icon-delete"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-collapse-item>
            <el-collapse-item title="优美图集" name="4">
              <div style="padding-top: 30px">
                <el-form-item label="精彩图片:" prop="commodityName">
                  <div class="pictures">
                    <div
                      class="pictures_box"
                      v-for="(item, index) in form.resultPhotos"
                      :key="index"
                    >
                      <div class="pictures_box_left">
                        <el-upload
                          class="avatar-uploader"
                          :class="{ hide: item.hideUpload }"
                          action=""
                          :on-preview="
                            (res) => {
                              return handlePictureCardPreviews(res, index)
                            }
                          "
                          :on-remove="
                            (res) => {
                              return handleRemove(res, index)
                            }
                          "
                          :http-request="
                            (res) => {
                              return uploadCategoryPics(res, index)
                            }
                          "
                          list-type="picture-card"
                          :file-list="item.filelist"
                          :limit="1"
                          accept=".jpg,.jpeg,.png,.tif,.gif"
                        >
                          <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
                          <img width="100%" :src="item.photoAddress" alt />
                        </el-dialog>
                      </div>
                      <div class="pictures_box_right">
                        <div>
                          <el-input v-model="item.photoName" placeholder="请输入图片名称" />
                          <div style="padding: 5px 0px"></div>
                          <el-input
                            v-model="item.photoDescribe"
                            type="textarea"
                            :autosize="{ minRows: 3, maxRows: 4 }"
                            placeholder="请输入图片描述"
                            maxlength="100"
                            show-word-limit
                          />
                        </div>
                        <div style="padding: 0px 20px">
                          <span v-if="index == 0" style="color: #4e93fb" @click="addgeneral"
                            ><i class="el-icon-circle-plus"></i
                          ></span>
                          <span v-if="index > 0" style="color: #ff7575" @click="delgeneral(index)"
                            ><i class="el-icon-delete"></i
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </div>
            </el-collapse-item>
            <el-collapse-item title="参访企业" name="5">
              <div style="padding-top: 30px">
                <el-form-item label="参访企业:" prop="commodityName">
                  <div class="general_input">
                    <div class="input_flex" v-for="(item, index) in companylist" :key="index">
                      <div class="name_form">
                        <el-select v-model="item.name" placeholder="请选择参访企业">
                          <el-option
                            v-for="item in operatingList"
                            :key="item.id"
                            :label="item.companyName"
                            :value="item.companyName"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div style="display: flex">
                        <span v-if="index == 0" style="color: #4e93fb" @click="addcompany"
                          ><i class="el-icon-circle-plus"></i
                        ></span>
                        <span v-if="index > 0" style="color: #ff7575" @click="delcompany(index)"
                          ><i class="el-icon-delete"></i
                        ></span>
                      </div>
                    </div>
                  </div>
                </el-form-item></div
            ></el-collapse-item>
          </el-collapse>
          <div class="applets_share">
            <h4>小程序分享链接信息</h4>
            <el-form-item label="分享链接标题:" prop="shareTitle">
              <el-input v-model="form.shareTitle" placeholder="请输入分享链接标题" />
            </el-form-item>
            <el-form-item label="分享链接图片:" prop="sharePictures">
              <div class="pictures_box_left">
                <img-big-upload
                  :imageShow.sync="form.sharePictures"
                  :addTitleShow="false"
                  @removeImg="removeImgDians"
                  @uploadOneImgShow="uploadOneImgShowDians"
                />
              </div>
              <div class="img_tips">
                <span>(建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于12MB)</span>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center; padding-bottom: 30px"
      >
        <el-button type="primary" @click="submitForm">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  gwtresult,
  getdemand,
  editresult,
  addresult,
  getVisitingCompany,
  getFieldList,
  getreleaseResult,
  getdemandtop
} from '@/api/demand'
import { uploadAdmin } from '@/api/common'
export default {
  name: 'ConfiguringReports',
  computed: {
    inspectDemandId() {
      return this.$route.query.inspectDemandId * 1
    }
  },
  data() {
    return {
      demandinfo: {}, //头部
      form: {
        areasInvestigation: '',
        companyIntroductory: '',
        companyNames: '',
        companyNumber: 0,
        coverImages: '', //封面图
        id: null,
        inspectDemandId: this.$route.query.inspectDemandId * 1,
        inspectIntroductory: '', //人员描述
        inspectNumber: 1, //考察人员
        participants: 1, //参访嘉宾
        participantsIntroductory: '', //嘉宾描述
        projectIntroduction: '',
        projectName: '', //项目名称
        sharePictures: '',
        shareTitle: '',
        resultOutcomes: [
          {
            companyName: '',
            id: null,
            intentionDescription: '',
            isIntention: '',
            percentage: null,
            resultId: null
          }
        ],
        resultPhotos: [
          {
            id: null,
            photoAddress: '',
            photoDescribe: '',
            photoName: '',
            resultId: null,
            hideUpload: false
          }
        ],
        isAdd: false
      },
      //考察领域
      list: [
        {
          areas: [],
          general: ''
        }
      ],
      operaList: [],
      activeNames: ['1', '2'],
      companylist: [{ name: '' }],
      operatingList: [],
      dialogVisible: false,
      topinfo: {} //更新时间
    }
  },
  created() {
    this.getdemand()
    this.getdemandtop()
    this.gwtresult()
    this.getFieldList()
    this.getCompanyList()
  },
  methods: {
     //最近更新时间
     async getdemandtop() {
      const res = await getdemandtop({ inspectDemandId: this.inspectDemandId })
      if (res.data.resultCode == 200) {
        this.demandinfo = res.data.data
      }
    },
    //参访企业
    async getCompanyList() {
      const res = await getVisitingCompany({inspectDemandId:this.inspectDemandId})
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    //考察领域
    async getFieldList() {
      const res = await getFieldList({codeType:'010'})
      this.operaList = res.data.data
    },
    //获取信息
    async getdemand() {
      const res = await getdemand(this.inspectDemandId)
      if (res.data.resultCode == 200) {
        this.topinfo = res.data.data
      }
    },
    //获取行程记录
    async gwtresult() {
      const res = await gwtresult(this.inspectDemandId)
      if (res.data.resultCode == 200) {
        this.form = res.data.data
        if (this.form.areasInvestigation != null) {
          this.list = JSON.parse(this.form.areasInvestigation)
       
        }
        if (this.form.sharePictures == null) {
          this.form.sharePictures = ''
        }
        if (this.form.coverImages == null) {
          this.form.coverImages = ''
        }
        if( this.form.companyNames!=null){
          this.companylist=[]
          this.form.companyNames= this.form.companyNames.split(',')
          this.form.companyNames.forEach((o)=>{
            this.companylist.push(
              {
                name:o
              }
            )
          })
          this.activeNames.push('5')
        }

        if (this.form.resultOutcomes.length == 0) {
          this.form.resultOutcomes = [
            {
              companyName: '',
              id: null,
              intentionDescription: '',
              isIntention: '',
              percentage: 0,
              tripId: this.$route.query.id
            }
          ]
         
        }else{
          this.activeNames.push('3')
        }
        if (this.form.resultPhotos.length == 0) {
          this.form.resultPhotos = [
            {
              id: null,
              photoAddress: '',
              photoDescribe: '',
              photoName: '',
              tripId: this.$route.query.id,
              hideUpload: false
            }
          ]
        
        } else {
          this.form.resultPhotos.forEach((o) => {
            if (o.photoAddress) {
              o.filelist = [{ name: o.photoAddress, url: o.photoAddress }]
              o.hideUpload = true
            }
          })
          this.activeNames.push('4')
        }

        this.form.tripId = this.$route.query.id
      }
    },

    deliption(index) {
      this.form.resultOutcomes.splice(index, 1)
    },

    addiption() {
      this.form.resultOutcomes.push({
        id: null,
        photoAddress: '',
        photoDescribe: '',
        photoName: '',
        tripId: this.$route.query.id
      })
    },
    //新增精彩图片
    addgeneral() {
      this.form.resultPhotos.push({
        id: null,
        photoAddress: '',
        photoDescribe: '',
        photoName: '',
        tripId: this.$route.query.id,
        hideUpload: false
      })
    },
    handlePictureCardPreviews(file, index) {
      this.form.resultPhotos[index].photoAddress = file.url
      this.dialogVisible = true
    },
    //上传图片
    async uploadCategoryPics(params, index) {
      const form = new FormData()
      form.append('file', params.file)

      const res = await uploadAdmin(form)
      if (res.data.resultCode === 200) {
        this.form.resultPhotos[index].photoAddress = res.data.data
        this.form.resultPhotos[index].hideUpload = true
      }
    },
    handleRemove(file, index) {
      this.form.resultPhotos[index].photoAddress = ''
      this.form.resultPhotos[index].hideUpload = false
    },
    //删除精彩图片
    delgeneral(index) {
      this.form.resultPhotos.splice(index, 1)
    },
    addareas() {
      this.list.push({
        areas: [],
        general: ''
      })
    },
    delareas(index) {
      this.list.splice(index, 1)
    },
    //新增参访企业
    addcompany() {
      this.companylist.push({
        name: ''
      })
    },
    //删除参访企业
    delcompany(index) {
      this.companylist.splice(index, 1)
    },
    //获取长地址图片
    uploadOneImgShowDian(val) {
      this.form.coverImages = val
    },
    /**
     * 删除图
     */
    removeImgDian(val) {
      this.form.coverImages = ''
    },
    //获取长地址图片
    uploadOneImgShowDians(val) {
      this.form.sharePictures = val
    },
    /**
     * 删除图
     */
    removeImgDians(val) {
      this.form.sharePictures = ''
    },
    //发布报告
    releaseClick(){
      const ids = this.form.id
      if(ids!=null){
        this.$alert('您是否确认发布报告？')
        .then(function () {
          return getreleaseResult({resultId:ids})
        })
        .then(() => {
          this.$message.success('发布成功')
          this.$router.go(-1)
        })
        .catch(() => {})
      }else{
        this.$message.warning('请先配置报告！')
      }
    
    },
    /** 提交按钮 */
    submitForm() {
      this.form.companyNames = []
      this.companylist.forEach((o) => {
        this.form.companyNames.push(o.name)
      })
      this.form.companyNames = this.form.companyNames.toString()
      this.form.areasInvestigation = JSON.stringify(this.list)

      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.form.id == null) {
            addresult(this.form).then((response) => {
              this.$message.success('保存成功')
              this.$router.go(-1)
            })
          } else {
            editresult(this.form).then((response) => {
              this.$message.success('保存成功')
              this.$router.go(-1)
            })
          }
        }
      })
    },
    //取消
    cancel() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.hide ::v-deep .el-upload--picture-card {
  display: none;
}
::v-deep .el-upload-list__item {
  transition: none !important;
}
.avatar-uploader .el-upload {
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

::v-deep .el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 124px;
  height: 124px;
  cursor: pointer;
  line-height: 124px;
  vertical-align: top;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 124px;
  height: 124px;
  text-align: center;
}

::v-deep .el-collapse-item__header {
  display: flex;
  align-items: center;
  height: 68px;
  line-height: 68px;
  background-color: #fff;
  color: #1a1a1a;
  cursor: pointer;
  border-bottom: 1px solid #ebeef5;
  font-size: 20px;
  font-weight: 500;
  transition: border-bottom-color 0.3s;
  outline: 0;
}
::v-deep .image_upload .el-upload--picture-card {
  font-size: 28px;
  background-color: #f7f8fa;
  color: #8c939d;
  border: none;
  width: 68px;
  height: 68px;
  line-height: 68px;
  text-align: center;
}
::v-deep .el-upload--picture-card i {
  font-size: 20px;
  color: #8c939d;
}
::v-deep .image_upload .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
  text-align: center;
}
::v-deep .pictures_box_left .image_upload .el-upload--picture-card {
  font-size: 28px;
  background-color: #f7f8fa;
  color: #8c939d;
  border: none;
  width: 124px;
  height: 124px;
  line-height: 124px;
  text-align: center;
}
::v-deep .pictures_box_left .image_upload .el-upload-list--picture-card .el-upload-list__item {
  width: 124px;
  height: 124px;
  text-align: center;
}
::v-deep .form_essential .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 41px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .cooperation .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 41px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 386px;
}
::v-deep .general_input .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 41px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 526px;
}
::v-deep .proportion .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 41px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 180px;
}
::v-deep .el-textarea {
  position: relative;
  display: inline-block;
  width: 596px;
  vertical-align: bottom;
  font-size: 14px;
}
::v-deep .el-form-item__content {
  line-height: 40px;
  position: relative;
  font-size: 19px;
}
::v-deep .el-collapse {
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}
.ConfiguringReports {
  .top_info {
    display: flex;
    align-items: center;
    img{
      width: 18px;
      height: 17px;
    }
        .top_time {
          display: flex;
        justify-content: space-around;
          font-size: 14px;
          width: 356.84px;
          height: 38px;
          background: #fafafa;
          border-radius: 8px;
          line-height: 38px;
        }
      }
      .head_img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 15px 10px;
    background: #ffffff;
    .storeCenter_item_top_center {
      width: 600px;
    }
    .img_title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 10px 5px 10px;

      .img {
        width: 100px;
        height: 100px;
        border-radius: 5px;
      }

      .title {
        margin: 0px 0px 0px 10px;
        display: flex;
        flex-direction: column;

        .merchant_name {
          font-weight: 550;
          font-size: 18px;
          color: #1f2d3d;
          margin: 0px;
          position: relative;
          top: -5px;
        }

        .merchant_type {
          position: relative;
          top: 10px;
        }
      }
    }
  }
  .essential_information {
    margin-top: 20px;
    padding: 10px 0px;
    background: #ffffff;
    .general_input {
      width: 596px;
      border: 1px solid #d4d4d4;
      padding: 14px 16px;

      .input_flex {
        display: flex;
        justify-content: space-between;
        padding: 7px 0px;
        span {
          padding-left: 15px;
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
    .form_essential {
      padding: 0px 30px 0px 30px;
      .img_tips {
        position: relative;
        top: -10px;
        color: #6ba1ff;
        font-size: 12px;
      }

      h4 {
        color: #146aff;
        padding: 0px 30px;
      }
      .proportion {
        padding-bottom: 10px;
      }
      .pictures {
        width: 817px;
        border: 1px dashed #c4c4c4;
        padding: 20px;
        .cooperation {
          display: flex;
          padding-bottom: 10px;
          .proportion {
            display: flex;
            position: relative;
            left: -100px;
          }
        }
        .pictures_box {
          display: flex;
          padding-bottom: 20px;
          .pictures_box_left {
            padding-right: 20px;
          }
          .pictures_box_right {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
